<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Taloyhtiöt / Kiinteistöt</div>

    <v-card class="mt-2">
      <v-card-title v-if="!isGroupMode()">
        <v-spacer></v-spacer>
        <router-link style="text-decoration: none" to="/condominium/create">
          <v-btn v-if="isAuthorized('condominium', 'create')" color="info">Uusi taloyhtiö</v-btn>
        </router-link>
      </v-card-title>

      <v-container>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Hae kaikista kentistä..."
              single-line
              hide-details
              solo
              @keyup.enter="getDataFromApi()"
              @click:append="getDataFromApi()"
            ></v-text-field>
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-container>

      <v-data-table
        :headers="computedHeaders"
        :items="condominiums"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <!-- CreatedBy -->
        <template #[`item.createdBy`]="{ item }">
          <span
            class="primary--text"
            style="cursor: pointer"
            @click="switchToAccount(item.createdBy, '/condominiums')"
            ><strong>{{ item.createdBy.name }} </strong></span
          >
        </template>

        <!-- Overview -->
        <template #[`item.name`]="{ item }">
          <router-link
            v-if="!isGroupMode()"
            :to="`/condominium/overview/${item._id}`"
            style="text-decoration: none"
          >
            <div class="font-weight-bold">
              {{ item.name }}
            </div>
          </router-link>
          <div v-else class="font-weight-bold">
            {{ item.name }}
          </div>
          <span>{{ item.address }}</span>
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container">
            <v-icon
              v-if="isAuthorized('condominium', 'read')"
              title="Muokkaa"
              small
              @click="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon
              v-if="isAuthorized('condominium', 'delete')"
              title="Poista"
              small
              @click="deleteItem(item)"
              >mdi-delete</v-icon
            >
            <v-icon
              v-if="isAuthorized('condominium', 'create')"
              title="Luo vuokrakohde"
              small
              @click="
                setDefaultApartment();
                $router.push(`/condominium/${item._id}/create-apartment`).catch(() => {});
              "
              >mdi-plus-circle-outline</v-icon
            >
          </div>
        </template>
        <template #no-data>
          <h2>Ei taloyhtiöitä</h2>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import globalValues from "@/configs/globalValues";

export default {
  title: "Taloyhtiöt / Kiinteistöt",

  mixins: [mixins],

  data: () => ({
    loading: true,
    options: {},
    dialog: false,
    search: "",
    headers: [
      {
        text: "Tili",
        value: "createdBy",
        show: [1],
      },
      { text: "Taloyhtiö / Kiinteistö", value: "name", show: [0, 1] },
      { text: "Y-tunnus", value: "businessId", show: [0, 1] },
      {
        text: "Postinumero",
        value: "zipCode",
        show: [0, 1],
      },
      {
        text: "Kaupunki",
        value: "city",
        show: [0, 1],
      },
      {
        text: "Kaupunginosa",
        value: "neighborhood",
        show: [0, 1],
      },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
        show: [0],
      },
    ],
    globalValues,
  }),

  computed: {
    ...mapState("condominium", ["condominiums", "totalLength"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),

    computedHeaders() {
      return this.headers.filter((el) => {
        if (this.isGroupMode()) {
          if (el.show.includes(1)) return el;
        } else {
          if (el.show.includes(0)) return el;
        }
      });
    },
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  methods: {
    ...mapActions("condominium", ["getAllCondominiums", "deleteCondominium"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),
    ...mapMutations("apartment", ["setDefaultApartment"]),
    ...mapGetters("account", ["getSelectedAccounts"]),

    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      let search = this.search.trim().toLowerCase();
      let url = `/api/v1/condominium/getAll?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;

      await this.getAllCondominiums({ url, data: { accountSelector: this.getSelectedAccounts() } });

      this.loading = false;
    },

    editItem(item) {
      this.$router.push("/condominium/edit/" + item._id);
    },

    deleteItem(item) {
      if (confirm("Haluatko varmasti poistaa kiinteistön " + item.name))
        this.deleteCondominium(item._id);
    },
  },
};
</script>

<style scoped></style>
